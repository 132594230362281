import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export class BaseService {
  protected host: string = environment.apiUrl;

  public responseType: string;

  public extraOptions: any = {};

  public method = {
    read: null,
    create: null,
    update: null,
    delete: null,
  };

  protected token = sessionStorage.getItem("token");

  constructor(
    public http: HttpClient,
    public basePath: string = "",
    public entityName: string = ""
  ) { }

  protected headers(isFormData = false): HttpHeaders {
    let result: any = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      CompanyName: "ASAP",
    };

    if (isFormData) {
      delete result["Content-Type"];
    }

    this.token = sessionStorage.getItem("token");

    if (this.token) {
      result.Authorization = this.token;
    }

    return new HttpHeaders(result);
  }

  protected options(isFormData = false): any {
    let options: any = {};

    options["headers"] = this.headers(isFormData);
    options["responseType"] = this.responseType;

    for (let key in this.extraOptions) {
      options[key] = this.extraOptions[key];
    }

    return options;
  }

  getResources(params = {}): Observable<any> {
    return this.http.get(this.resourceUrl(null, params), this.options());
  }

  getGroup(params = {}): Observable<any> {
    return this.http.get(this.group_Id(null, params), this.options());
  }

  group_Id(arg0: null, params: {}): string {
    throw new Error("Method not implemented.");
  }

  getResource(id, params = {}): Observable<any> {
    console.log("entrou na função");
    return this.http.get(this.resourceUrl(id, params), this.options());
  }

  createResource(data, params = {}): Observable<any> {
    let isFormData = false;

    if (data instanceof FormData) {
      isFormData = true;
    }

    return this.http.post(
      this.resourceUrl(null, params, "create"),
      data,
      this.options(isFormData)
    );
  }

  updateResource(data, params = {}, customId = false): Observable<any> {
    if (!customId) {
      let formattedData = { ...data };
      if (formattedData.platform) {
        formattedData.platform = JSON.stringify(formattedData.platform);
      }
      return this.http.put(
        this.resourceUrl(data.id, params, "update"),
        formattedData,
        this.options()
      );
    } else {
      let formattedData = { ...data };
      if (formattedData.platform) {
        formattedData.platform = JSON.stringify(formattedData.platform);
      }
      return this.http.put(
        this.resourceUrl(null, params, "update"),
        data,
        this.options()
      );
    }
  }

  deleteResource(id, params = {}): Observable<any> {
    return this.http.delete(
      this.resourceUrl(id, params, "delete"),
      this.options()
    );
  }

  deleteWithUrlResource(): Observable<any> {
    return this.http.delete(this.resourceUrl(), this.options());
  }

  resourceUrl(id = null, params = {}, method = "read") {
    console.log("[params]", params);
    const endpoint = [
      this.host,
      this.basePath,
      this.urlParameters(params),
      this.method[method] || this.entityName,
      id,
    ]
      .filter((element) => element != false)
      .join("/")
      .replace(/\/$/, "");

    let url = endpoint + this.queryString(params);

    let client_id: any = sessionStorage.getItem("client");
    if (client_id) {
      if (url.indexOf("?") >= 0) url = url + "&";
      else url = url + "?";
      url = url + "client_id=" + client_id;
    }

    return url;
  }

  protected urlParameters(params) {
    var urlParameters = [];

    for (var placeholder in params) {
      if (/.*_id$/.test(placeholder)) {
        urlParameters.push(`${placeholder}/${params[placeholder]}`);
      }
    }

    return urlParameters.join("/");
  }

  protected queryString(params) {
    if (!params) {
      params = {};
    }

    let result = "";
    if (params.router) {
      result = `/${params.router}`;
    }
    if (params.query) {
      if (result != "") {
        result = result + `?${params.query}`;
      } else {
        result = `?${params.query}`;
      }
    }
    return result;
  }
}
