import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TopMenuComponent } from './top-menu/top-menu.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TopMenuComponent,
    FooterComponent
  ],
  exports: [
    TopMenuComponent,
    FooterComponent
  ],
})
export class StructureModule { }
