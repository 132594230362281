import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StaticPageService extends BaseService {

  constructor(
    public readonly http: HttpClient,
  ) { 
    super(http);
    this.entityName = "static-page";
    this.basePath = "api/v2/admin";
  }

}
