import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html'
})
export class TopMenuComponent  {

    @Input() title: string;
    @Output() handleSavePage = new EventEmitter<any>();

    closeWindow() {
        window.close();
    }
}
