import { NgModule } from '@angular/core';
import { StaticPageComponent } from './static-page/static-page.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    StaticPageComponent
  ],
  exports: [
    StaticPageComponent
  ],

})

export class ComponentsModule { }
